import './App.css';
import React, {useEffect, useState} from 'react';
import Plot from 'react-plotly.js';
//import {FeatureNames} from './res.js'
import { Form, Button} from 'react-bootstrap';

const MY_INT_MAX = Number.MAX_SAFE_INTEGER;
const MY_INT_MIN = Number.MIN_SAFE_INTEGER;
function Dplot({onDelete, selectedDate1, selectedDate2, dataSrc, highlightedId, panelId, updatehighlightedId}){

    //const labelItems = ['COV', 'covidx', 'fluA', 'flub', 'ms2'];
    //const AlgoItems = ['HyperCT', 'RatioCT'];

    const [Num, setNum] = useState(null);
    const [Data, setData] = useState(null);
    const [LabelList, setLabelList] = useState([]);
    const [AlgoList, setAlgoList] = useState([]);
    const [FeatNameList, setFeatNameList] = useState(null);
    const [selectedLabel, setSelectedLabel] = useState(null);
    const [selectedAlgo, setSelectedAlgo] = useState(null);
    const [selectedItemX, setSelectedItemX] = useState(null);
    const [selectedItemY, setSelectedItemY] = useState(null);
    const [xData, setxData] = useState([1, 2, 3]);
    const [yData, setyData] = useState([3, 4, 5]);
    const [uData, setuData] = useState(null);
    const [vData, setvData] = useState(null);
    const [showStats, setshowStats] = useState(false);
    const [detail, setDetail] = useState(null);
    const [xbar, setxbar] = useState(null);
    const [ybar, setybar] = useState(null);
    const [Stats, setStats] = useState([0,0,0,0,0,0,0,0]);
    const [BarSize, setBarSize] = useState([MY_INT_MIN, MY_INT_MAX, MY_INT_MIN, MY_INT_MAX]);
    const [colorArray, setcolorArray]= useState([]);

    const color1 = 'rgba(255,25,25,0.48)';
    const color2 = 'rgba(0,127,255,0.48)';
    const color3 = 'rgba(196,196,196,0.28)';
    const color4 = 'rgba(51,183,140,0.7)';

    const color1d = 'rgba(255,25,25,0.1)';
    const color2d = 'rgba(0,127,255,0.1)';
    const color1s = 'rgba(255,25,25,0.8)';
    const color2s = 'rgba(0,127,255,0.8)';

    const dataReset = () => {
        setxData([1,2,3]);
        setyData([4,5,6]);
        setuData(null);
        setvData(null);
        setDetail(null);
        return 0;
    }

    const handleSelectLabel = (item) => {
        setSelectedLabel(item);
        setSelectedAlgo(null);
        setSelectedItemX(null);
        setSelectedItemY(null);
        dataReset();
    };
    const handleSelectAlgo = (item) => {
        setSelectedAlgo(item);
        setSelectedItemX(null);
        setSelectedItemY(null);
        dataReset();
    };

    useEffect(() => {
        setSelectedLabel(null);
        setSelectedAlgo(null);
        setSelectedItemX(null);
        setSelectedItemY(null);
        dataReset();
    }, [dataSrc]);

    const HandleHighlightedData =  async (data) => {
        let IdList = [...highlightedId];
        IdList.push(data);
        updatehighlightedId(IdList);
        return 0;
    }

    const getLabelList =  async () => {
        const url = `${process.env.REACT_APP_API}/labelName`;
        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                //console.log(data);
                setLabelList(data["Labels"]);
            })
            .catch(error => console.log(error));
        return 0;
    }


    const getAlgoList =  async () => {
        const url = `${process.env.REACT_APP_API}/algoName`;
        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setAlgoList(data["Algos"]);
            })
            .catch(error => console.log(error));

        return 0;
    }

    const getFeatName =  async () => {
        const url = `${process.env.REACT_APP_API}/featName`;
        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                //console.log("FeatList", data);
                setFeatNameList(data);
            })
            .catch(error => console.log(error));
        return 0;
    }

    const getData =  async (label) => {
        if (label === null){return;}
        const url = `${process.env.REACT_APP_API}/feat/${label}`;
        //console.log("DATA URL", url);

        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            },
            body: JSON.stringify({
                start: selectedDate1,
                end: selectedDate2,
                source: dataSrc,
            }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                //console.log(data);
                setData(data);
            })
            .catch(error => console.log(error));
        return 0;
    }

    const getPlotDetail =  async (str1, str2) => {
        if (str2.length > 2){str2 = str2.substr(2);}
        await HandleHighlightedData(str1);
        const url = `${process.env.REACT_APP_API}/details/${str1}/${str2}`;

        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            },
            body: JSON.stringify({
                source: dataSrc,
            }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                //console.log("DETAIL deta", data);
                setuData(data["time"]);
                setvData(data["val"]);
            })
            .catch(error => console.log(error));
        return 0;
    }

    const setColorArrayValue = () => {
        if (LabelList === null || Data === null || selectedAlgo === null || selectedAlgo === ""){return;}
        let templist = [];
        Data["MarkList"][selectedAlgo].forEach((item, index) => {
            if (item === -1){templist.push(color3);}
            else if (item === 0){templist.push(color1);}
            else if (item === 1){templist.push(color2);}
        })

        if (JSON.stringify(colorArray) === JSON.stringify(templist)) {
            return;
        }

        setcolorArray(templist);
    }

    useEffect(() => {
        if (selectedItemX === null || selectedItemY === null){return;}
        if (Data === null || selectedAlgo === null || colorArray === null){return;}
        if (highlightedId.length === 0){setColorArrayValue(); return;}

        let templist = [];

        Data["IdList"][selectedAlgo].forEach((item, index) => {
            if (highlightedId.includes(item)){
                //console.log("Panel ", panelId, "Checked ID: ", item, "INDEX ", index);
                if (colorArray[index] === color1 || colorArray[index] === color1d){templist.push(color1s);}
                else if (colorArray[index] === color2 || colorArray[index] === color2d){templist.push(color2s);}
                else{templist.push(colorArray[index]);}
            }
            else{
                if (colorArray[index] === color1){templist.push(color1d);}
                else if (colorArray[index] === color2){templist.push(color2d);}
                else{templist.push(colorArray[index]);}
            }
        })
        //console.log(localIdList); console.log(templist);

        if (JSON.stringify(colorArray) === JSON.stringify(templist)) {
            return;
        }
        setcolorArray(templist);
    }, [highlightedId, selectedItemX, selectedItemY, Data, selectedAlgo, colorArray]);


    useEffect(() => {
        getFeatName();
        getLabelList();
        getAlgoList();
    }, []);


    useEffect(() => {
        setColorArrayValue();
    }, [Data, selectedAlgo, LabelList]);


    useEffect(() => {
        getData(selectedLabel);
    }, [selectedLabel, selectedDate1, selectedDate2]);

    useEffect(() => {
        setDetail(null);
    }, [selectedDate1, selectedDate2]);

    useEffect(() => {
        if (Data === null || selectedAlgo === null || selectedItemX === null || selectedItemX === ""){return;}

        if (Data["Range"][selectedAlgo] === null || Object.keys(Data["Range"][selectedAlgo]).length === 0){
            console.error("Error Code 0x0b00: Empty data for selected Algo");
            dataReset();
            return;
        }
        setxData(Data["featureValues"][selectedAlgo][selectedItemX]);
        let xmax = Data["Range"][selectedAlgo][selectedItemX][0];
        let xmin = Data["Range"][selectedAlgo][selectedItemX][1];
        setBarSize(prevState => [xmax, xmin, ...prevState.slice(2)]);
        setxbar(null);
        setybar(null);
    }, [Data, selectedItemX, selectedAlgo]);

    useEffect(() => {
        if (Data === null || selectedAlgo === null ||selectedItemY === null || selectedItemY === ""){return;}
        if (Data["Range"][selectedAlgo] === null || Object.keys(Data["Range"][selectedAlgo]).length === 0){
            console.error("Error Code 0x0b00: Empty data for selected Algo");
            dataReset();
            return;
        }
        setyData(Data["featureValues"][selectedAlgo][selectedItemY]);
        let ymax = Data["Range"][selectedAlgo][selectedItemY][0];
        let ymin = Data["Range"][selectedAlgo][selectedItemY][1];
        setBarSize(prevState => [...prevState.slice(0, -2), ymax, ymin]);
        setxbar(null);
        setybar(null);

    }, [Data, selectedItemY, selectedAlgo]);


    useEffect(() => {
        if (Data === null || selectedAlgo === null || detail === null){return;}
        getPlotDetail(Data["IdList"][selectedAlgo][detail], Data["ChannelList"][selectedAlgo][detail])

    }, [detail, Data, selectedAlgo]);

    useEffect(() => {
        if (ybar == null){return;}
        if (xbar == null){return;}
        // 1,2,3,4, false/true
        let templist = [0,0,0,0,0,0,0,0];

        xData.forEach((item, index) => {
            let x = item;
            let y = yData[index];
            let c = colorArray[index];

            if (x >= xbar && y >= ybar){
                if (c === color1){templist[0]++;}
                else{templist[1]++;}
            }
            else if (x >= xbar && y < ybar){
                if (c === color1){templist[2]++;}
                else{templist[3]++;}
            }
            else if (x < xbar && y < ybar){
                if (c === color1){templist[4]++;}
                else{templist[5]++;}
            }
            else {
                if (c === color1){templist[6]++;}
                else{templist[7]++;}
            }
        })
        setStats(templist)
    }, [xbar, ybar, colorArray, xData, yData]);
    
    return (
        <div className="plotcom">
            <div className="w-90 mx-autos selectbox mt-3">
                <label className="mt-2 mb-1">Select a targeting label </label>
                <Form>
                    <Form.Select
                        size = "sm"
                        value={selectedLabel || ''}
                        onChange={(e) => handleSelectLabel(e.target.value)}
                        className="w-80"
                    >
                        <option value="">Select an option (Clear Selected)</option>
                        {LabelList.length > 0? (
                            LabelList.map((item, index) => (
                                <option key={index} value={item}>
                                    {item}
                                </option>
                            ))
                        ) : null}

                    </Form.Select>



                    <label className="mt-2 mb-1">Select an algorithm type</label>
                    <Form.Select
                        size = "sm"
                        value={selectedAlgo || ''}
                        onChange={(e) => handleSelectAlgo(e.target.value)}
                        className="w-80"
                    >
                        <option value="">Select an option (Clear Selected)</option>
                        {AlgoList.length > 0  ? (
                            AlgoList.map((item, index) => (
                                <option key={index} value={item}>
                                    {item}
                                </option>
                            ))
                        ) : null}

                    </Form.Select>
                    <label className="mt-2 mb-1">Select an option for x-axis</label>
                    <Form.Select
                        size = "sm"
                        value={selectedItemX || ''}
                        onChange={(e) => setSelectedItemX(e.target.value)}
                        className="w-80"
                    >
                        <option value="">Select an option (Clear Selected)</option>
                        {(selectedAlgo !== null && Data!== null && Data["Range"].hasOwnProperty(selectedAlgo) &&
                            Data["Range"][selectedAlgo] !== null && Object.keys(Data["Range"][selectedAlgo]).length !== 0 && FeatNameList !== null ) ? (
                            FeatNameList[dataSrc][selectedAlgo].map((item, index) => (
                                <option key={index} value={item}>
                                    {item}
                                </option>
                            ))
                        ) : null}
                    </Form.Select>

                    {/*{selectedItemx1 && <p>You selected: {selectedItemx1}</p>}*/}

                    <label className="mt-2 mb-1">Select an option for y-axis</label>
                    <Form.Select
                        size = "sm"
                        value={selectedItemY || ''}
                        onChange={(e) => setSelectedItemY(e.target.value)}
                        className="w-80"
                    >
                        <option value="">Select an option (Clear Selected)</option>
                        {(selectedAlgo !== null && Data!== null && Data["Range"].hasOwnProperty(selectedAlgo) &&
                            Data["Range"][selectedAlgo] !== null && Object.keys(Data["Range"][selectedAlgo]).length !== 0 && FeatNameList !== null ) ? (
                            FeatNameList[dataSrc][selectedAlgo].map((item, index) => (
                                <option key={index} value={item}>
                                    {item}
                                </option>
                            ))
                        ) : null}
                    </Form.Select>
                </Form>
            </div>
            <div className="w-90 mx-auto">

                <div>
                    <Form.Check
                        type="checkbox"
                        label="Show Stats"
                        checked={showStats}
                        onChange={() => setshowStats(!showStats)}
                    />
                </div>

                {showStats && (
                    <div>
                        <div className="d-flex justify-content-between mt-2 upper-border">
                            <div className="w-45 mt-1">
                                <label>Select x value:</label>
                                <Form.Range
                                    type="range"
                                    min={BarSize[1] - 0.2}
                                    max={BarSize[0] + 0.2}
                                    step="0.05"
                                    value={xbar}
                                    onChange={(e) => {setxbar(e.target.value);}}
                                />
                            </div>
                            <div className="w-45 mt-1">
                                <label>Enter x val:</label>
                                <Form.Control
                                    type="number"
                                    size = "sm"
                                    min={BarSize[1] - 0.2}
                                    max={BarSize[0] + 0.2}
                                    value={xbar}
                                    onChange={(e) => {setxbar(e.target.value);}}
                                />
                            </div>
                        </div>
                        <p> X Value: {xbar}</p>


                        <div className="d-flex justify-content-between upper-border">
                            <div className="w-45 mt-1">
                                <label>Select y value:</label>
                                <Form.Range
                                    type="range"
                                    min={BarSize[3] - 0.05}
                                    max={BarSize[2] + 0.05}
                                    step="0.01"
                                    value={ybar}
                                    onChange={(e) => {setybar(e.target.value);}}
                                />
                            </div>
                            <div className="w-45 mt-1">
                                <label>Enter y val:</label>
                                <Form.Control
                                    type="number"
                                    size = "sm"
                                    min={BarSize[3] - 0.05}
                                    max={BarSize[2] + 0.05}
                                    value={ybar}
                                    onChange={(e) => {setybar(e.target.value);}}
                                />
                            </div>

                        </div>
                        <p> Y Value: {ybar}</p>


                        <div className="upper-border">
                            <div className="pt-1"> Stats info : {Stats[0]}, {Stats[1]}, {Stats[2]}, {Stats[3]},
                                {Stats[4]}, {Stats[5]}, {Stats[6]}, {Stats[7]},</div>
                            <span>R1: positive rate: {(Stats[1] / (Stats[0] + Stats[1])).toFixed(3)},
                                negative rate: {(Stats[0] / (Stats[0] + Stats[1])).toFixed(3)}</span><br/>
                            <span>R2: positive rate: {(Stats[3] / (Stats[2] + Stats[3])).toFixed(3)},
                                negative rate: {(Stats[2] / (Stats[2] + Stats[3])).toFixed(3)}</span><br/>
                            <span>R3: positive rate: {(Stats[5] / (Stats[4] + Stats[5])).toFixed(3)},
                                negative rate: {(Stats[4] / (Stats[4] + Stats[5])).toFixed(3)}</span><br/>
                            <span>R4: positive rate: {(Stats[7] / (Stats[6] + Stats[7])).toFixed(3)},
                                negative rate: {(Stats[6] / (Stats[6] + Stats[7])).toFixed(3)}</span><br/>
                        </div>
                    </div>
                )}

            </div>


            <div>
                <div className="w-95 mx-auto">
                    <div className="p-1 ">
                        <div className="mx-auto text-center mb-1">Feature Plot</div>
                        <div className="">
                            <Plot
                                className = "mx-auto d-block"
                                data={[
                                    {
                                        x: xData,
                                        y: yData,
                                        type: 'scatter',
                                        mode: 'markers',
                                        marker: {color: colorArray},
                                        name: "user_mark: N/A"
                                    },
                                ]}
                                layout={ {
                                    title: {
                                        text: '',
                                        automargin: true,
                                    },
                                    autosize: false,
                                    width: 400, height: 350,
                                    //autosizable: true,
                                    margin: {l: 50, r: 50, b: 30, t: 5, pad: 4},
                                    showlegend: true,
                                    legend: {x: 0, y: 1, xanchor: 'left'},
                                    xaxis: {
                                        automargin: true, showgrid: true, showspikes: true,
                                        spikemode: "across", spikethickness: 2,
                                        showline: true, linecolor: 'rgba(0,0,0,0.4)', linewidth: 1,
                                        mirror: 'ticks',
                                        zeroline: true, zerolinecolor: 'rgba(150,150,150,0.9)',
                                        title: {
                                            text: selectedItemX, standoff: 15
                                        }},
                                    yaxis: {
                                        automargin: true, showgrid: true, showspikes: true,
                                        spikemode: "across", spikethickness: 2,
                                        showline: true, linecolor: 'rgba(0,0,0,0.4)', linewidth: 1,
                                        mirror: 'ticks',
                                        zeroline: true, zerolinecolor: 'rgba(150,150,150,0.9)',
                                        title: {
                                            text: selectedItemY, standoff: 5
                                        }},
                                    shapes:(BarSize[0] !== MY_INT_MIN && BarSize[2] !== MY_INT_MIN
                                        && xbar !== null && ybar !== null) ? [
                                        {
                                            type: 'line', //line vertical
                                            x0: xbar, y0: BarSize[2], x1: xbar, y1:BarSize[3],
                                            line: {color: 'rgb(253,153,0)', width: 2}
                                        },
                                        {
                                            type: 'line', //line horizontal
                                            x0: BarSize[0], y0: ybar, x1: BarSize[1], y1:ybar,
                                            line: {color: 'rgb(253,153,0)', width: 2}
                                        },
                                    ] : ""
                                } }
                                config={{
                                    displaylogo: false
                                }
                                }
                                onClick={(data) => {
                                    let pn = '';
                                    let tn = '';

                                    for (let i = 0; i < data.points.length; i++) {
                                        pn = data.points[i].pointNumber;
                                        tn = data.points[i].curveNumber;
                                    }
                                    /*console.log(`Point idx: ${pn}, graph idx: ${tn}`);*/
                                    // console.log(BarSize);
                                    setDetail(pn);
                                }
                                }
                            />
                        </div>

                    </div>

                    <div className="p-1">
                        <div className="mx-auto text-center mb-2 mt-3">
                            {detail == null ? (
                                <div>Individual Sample Trace</div>
                            ) : (
                                <div>NO NAME SO FAR !!!</div>
                                //<div>{rawdata[detail].name}</div>
                            )}
                        </div>
                        <div className="">
                            <Plot
                                data={[
                                    {
                                        x: uData,
                                        y: vData,
                                        type: 'scatter',
                                        mode: 'lines+markers',
                                        marker:{size : 4}
                                    },
                                ]}
                                layout={ {
                                    title: {
                                        //text: detail1 == null ? "Individual Sample Trace" : "" + rawdata[detail1]["name"],
                                        text: "",
                                        font: {family: 'Arial', size: 16},
                                    },
                                    autosize: false,
                                    width: 400, height: 350,
                                    margin: {l: 50, r: 50, b: 30, t: 30, pad: 4},
                                    xaxis: {
                                        automargin: true,
                                        tickmode: "linear",
                                        tick0: 0, dtick: 5,
                                        showline: true, linecolor: 'rgba(0,0,0,0.4)', linewidth: 1,
                                        mirror: 'ticks',
                                        zeroline: true, zerolinecolor: 'rgba(150,150,150,0.9)',
                                        title: {text: "Time/(Min)", standoff: 15}},
                                    yaxis: {
                                        automargin: true,
                                        showline: true, linecolor: 'rgba(0,0,0,0.4)', linewidth: 1,
                                        mirror: 'ticks',
                                        zeroline: true, zerolinecolor: 'rgba(150,150,150,0.9)',
                                        title: {text: "smoothed_c", standoff: 10}},
                                    displaylogo: false,
                                } }
                                config={
                                    {
                                        displaylogo: false
                                    }
                                }
                                onClick={(data) => {;}}

                            />
                        </div>

                    </div>

                </div>

            </div>

            <div>
                <Button variant="danger" className="mx-auto mt-3 d-block mb-3" onClick={onDelete}>Delete Panel</Button>
            </div>

        </div>
    );
}
export default Dplot;