
export const FeatureNames = {
    "hyperCTv2_6to20_8to30": [
        "peak_width_time6to20",
        "sdAtRightIps_time6to20",
        "sdAt2min_time6to20",
        "sdAt3min_time6to20",
        "sdAt1min_time6to20",
        "sdAtEnd_time6to20",
        "sdAt9min_time6to20",
        "ct_time6to20",
        "sdAt8min_time6to20",
        "sdAt6min_time6to20",
        "sdAt5min_time6to20",
        "sdAt4min_time6to20",
        "pr_time6to20",
        "sdAt7min_time6to20",
        "sdAt10min_time6to20"
    ],
    "hyperCT": [],
    "ratioCT": [],
    "hyperCTv2_5to20_8to30": [
        "sdAtEnd_time5to20",
        "ct_time5to20",
        "sdAt8min_time5to20",
        "sdAt7min_time5to20",
        "sdAt5min_time5to20",
        "sdAt6min_time5to20",
        "sdAt10min_time5to20",
        "sdAt2min_time5to20",
        "peak_width_time5to20",
        "pr_time5to20",
        "sdAt4min_time5to20",
        "sdAt3min_time5to20",
        "sdAt9min_time5to20",
        "sdAt1min_time5to20",
        "sdAtRightIps_time5to20"
    ]
}
export const keyMapping = {    
     "ct_ratio1vs3_time0to20" : "ratioCT-ct_ratio1vs3",
     "ct_ratio1vs4_time0to20" : "ratioCT-ct_ratio1vs4",
     "ct_ratio1vs5_time0to20" : "ratioCT-ct_ratio1vs5",
     "ct_ratio1vs6_time0to20" : "ratioCT-ct_ratio1vs6",
     "ct_ratio1vs7_time0to20" : "ratioCT-ct_ratio1vs7",
     "ct_ratio2vs3_time0to20" : "ratioCT-ct_ratio2vs3",
     "ct_ratio2vs4_time0to20" : "ratioCT-ct_ratio2vs4",
     "ct_ratio2vs5_time0to20" : "ratioCT-ct_ratio2vs5",
     "ct_ratio2vs6_time0to20" : "ratioCT-ct_ratio2vs6",
     "ct_ratio2vs7_time0to20" : "ratioCT-ct_ratio2vs7",
     "sd_ratio1vs4_time0to20" : "ratioCT-sd_ratio1vs4",
     "sd_ratio2vs5_time0to20" : "ratioCT-sd_ratio2vs5",
     "sd_ratio1vs5_time0to20" : "ratioCT-sd_ratio1vs5",
     "sd_ratio2vs6_time0to20" : "ratioCT-sd_ratio2vs6",
     "sd_ratio2vs7_time0to20" : "ratioCT-sd_ratio2vs7",
     "sd_ratio1vs6_time0to20" : "ratioCT-sd_ratio1vs6",
     "sd_ratio2vs4_time0to20" : "ratioCT-sd_ratio2vs4",
     "sd_ratio1vs3_time0to20" : "ratioCT-sd_ratio1vs3",
     "sd_ratio1vs7_time0to20" : "ratioCT-sd_ratio1vs7",
     "sd_ratio2vs3_time0to20" : "ratioCT-sd_ratio2vs3",
  
     "peak_width_time6to20" : "hyperCT-peak_width",
     "ct_time6to20" : "hyperCT-ct",
     "pr_time6to20" : "hyperCT-pr",
     "sdAtRightIps_time6to20" : "hyperCT-sdAtRightIps",
     "sdAt1min_time6to20" : "hyperCT-sdAt1min",
     "sdAt2min_time6to20" : "hyperCT-sdAt2min",
     "sdAt3min_time6to20" : "hyperCT-sdAt3min",
     "sdAt4min_time6to20" : "hyperCT-sdAt4min",
     "sdAt5min_time6to20" : "hyperCT-sdAt5min",
     "sdAt6min_time6to20" : "hyperCT-sdAt6min",
     "sdAt7min_time6to20" : "hyperCT-sdAt7min",
     "sdAt8min_time6to20" : "hyperCT-sdAt8min",
     "sdAt9min_time6to20" : "hyperCT-sdAt9min",
     "sdAt10min_time6to20" : "hyperCT-sdAt10min",
     "sdAtEnd_time6to20" : "hyperCT-sdAtEnd"
  };


export const CategoryMapping = {
     "ct_ratio1vs3_time0to20" : "1",
     "ct_ratio1vs4_time0to20" : "1",
     "ct_ratio1vs5_time0to20" : "1",
     "ct_ratio1vs6_time0to20" : "1",
     "ct_ratio1vs7_time0to20" : "1",
     "ct_ratio2vs3_time0to20" : "1",
     "ct_ratio2vs4_time0to20" : "1",
     "ct_ratio2vs5_time0to20" : "1",
     "ct_ratio2vs6_time0to20" : "1",
     "ct_ratio2vs7_time0to20" : "1",
     "sd_ratio1vs4_time0to20" : "1",
     "sd_ratio2vs5_time0to20" : "1",
     "sd_ratio1vs5_time0to20" : "1",
     "sd_ratio2vs6_time0to20" : "1",
     "sd_ratio2vs7_time0to20" : "1",
     "sd_ratio1vs6_time0to20" : "1",
     "sd_ratio2vs4_time0to20" : "1",
     "sd_ratio1vs3_time0to20" : "1",
     "sd_ratio1vs7_time0to20" : "1",
     "sd_ratio2vs3_time0to20" : "1",

     "peak_width_time6to20" : "0",
     "ct_time6to20" : "0",
     "pr_time6to20" : "0",
     "sdAtRightIps_time6to20" : "0",
     "sdAt1min_time6to20" : "0",
     "sdAt2min_time6to20" : "0",
     "sdAt3min_time6to20" : "0",
     "sdAt4min_time6to20" : "0",
     "sdAt5min_time6to20" : "0",
     "sdAt6min_time6to20" : "0",
     "sdAt7min_time6to20" : "0",
     "sdAt8min_time6to20" : "0",
     "sdAt9min_time6to20" : "0",
     "sdAt10min_time6to20" : "0",
     "sdAtEnd_time6to20" : "0"
};


  export const featureItems = [
    "ct_ratio1vs3_time0to20" ,
    "ct_ratio1vs4_time0to20" ,
    "ct_ratio1vs5_time0to20" ,
    "ct_ratio1vs6_time0to20" ,
    "ct_ratio1vs7_time0to20" ,
    "ct_ratio2vs3_time0to20" ,
    "ct_ratio2vs4_time0to20" ,
    "ct_ratio2vs5_time0to20" ,
    "ct_ratio2vs6_time0to20" ,
    "ct_ratio2vs7_time0to20" ,
    "sd_ratio1vs4_time0to20" ,
    "sd_ratio2vs5_time0to20" ,
    "sd_ratio1vs5_time0to20" ,
    "sd_ratio2vs6_time0to20" ,
    "sd_ratio2vs7_time0to20" ,
    "sd_ratio1vs6_time0to20" ,
    "sd_ratio2vs4_time0to20" ,
    "sd_ratio1vs3_time0to20" ,
    "sd_ratio1vs7_time0to20" ,
    "sd_ratio2vs3_time0to20" ,
    "peak_width_time6to20" ,
    "ct_time6to20" ,
    "pr_time6to20" ,
    "sdAtRightIps_time6to20" ,
    "sdAt1min_time6to20" ,
    "sdAt2min_time6to20" ,
    "sdAt3min_time6to20" ,
    "sdAt4min_time6to20" ,
    "sdAt5min_time6to20" ,
    "sdAt6min_time6to20" ,
    "sdAt7min_time6to20" ,
    "sdAt8min_time6to20" ,
    "sdAt9min_time6to20" ,
    "sdAt10min_time6to20",
    "sdAtEnd_time6to20"
  ];
  export  const RatioItem = [
     "ct_ratio1vs3_time0to20" ,
    "ct_ratio1vs4_time0to20" ,
    "ct_ratio1vs5_time0to20" ,
    "ct_ratio1vs6_time0to20" ,
    "ct_ratio1vs7_time0to20" ,
    "ct_ratio2vs3_time0to20" ,
    "ct_ratio2vs4_time0to20" ,
    "ct_ratio2vs5_time0to20" ,
    "ct_ratio2vs6_time0to20" ,
    "ct_ratio2vs7_time0to20" ,
    "sd_ratio1vs4_time0to20" ,
    "sd_ratio2vs5_time0to20" ,
    "sd_ratio1vs5_time0to20" ,
    "sd_ratio2vs6_time0to20" ,
    "sd_ratio2vs7_time0to20" ,
    "sd_ratio1vs6_time0to20" ,
    "sd_ratio2vs4_time0to20" ,
    "sd_ratio1vs3_time0to20" ,
    "sd_ratio1vs7_time0to20" ,
    "sd_ratio2vs3_time0to20"
  ]


export  const HyperItem = [
    "peak_width_time6to20" ,
    "ct_time6to20" ,
    "pr_time6to20" ,
    "sdAtRightIps_time6to20" ,
    "sdAt1min_time6to20" ,
    "sdAt2min_time6to20" ,
    "sdAt3min_time6to20" ,
    "sdAt4min_time6to20" ,
    "sdAt5min_time6to20" ,
    "sdAt6min_time6to20" ,
    "sdAt7min_time6to20" ,
    "sdAt8min_time6to20" ,
    "sdAt9min_time6to20" ,
    "sdAt10min_time6to20" ,
    "sdAtEnd_time6to20"
]


export  const DataSrcItem = [
    "clinical_data" ,
    "usb_data" ,
]


