import './App.css';
import React, {useState, useEffect} from 'react';
import Dplot from "./Dplot";
import {DataSrcItem} from './res.js'
import {Button, Form} from 'react-bootstrap';

function App() {

    const [panels, setPanels] = useState([{ id: 1 }]);
    const [dataSrc, setdataSrc] = useState("clinical_data");
    const [selectedDate1, setSelectedDate1] = useState(null);
    const [selectedDate2, setSelectedDate2] = useState(null);
    const [highlightedId, sethighlightedId] = useState([]);
    const [accessToken, setAccessToken] = useState(null);

    useEffect(() => {
        const checkAccessToken = () => {
            const urlParams = new URLSearchParams(window.location.search);
            const accessToken = urlParams.get('access_token');
            setAccessToken(accessToken);
            if (accessToken) {
                localStorage.setItem('accessToken', accessToken);
            }
            else{
                console.error("Access Token Not Found");
            }
        };
        checkAccessToken();
    }, []);


    const addPanel = () => {
        setPanels([...panels, { id: panels.length + 1 }]);
    };

    const deletePanel = (panelId) => { // Filter out the panel with the specified ID
        setPanels(panels.filter(panel => panel.id !== panelId));
    };

    const handleSelectdataSrc = (item) => {setdataSrc(item);};
    const handleDateChange1 = (item) => {
        const newDate = item.target.value;
        setSelectedDate1(newDate);
    };
    const handleDateChange2 = (item) => {
        const newDate = item.target.value;
        setSelectedDate2(newDate);
    };

    const updatehighlightedId = (data) => {
        sethighlightedId(data);
    }

    const resetHighlightedId = () => {
        sethighlightedId([]);
    }

  return (
    accessToken &&
    <div className="App">
        <div className="w-40 mx-autos selectbox mt-3">
            <h1 className="text-center"> Data Analysis Plotting </h1>
            <div className="w-80 mx-autos mt-2">

                <label className="mt-2 mb-1">Select a Data source type</label>
                <Form>
                    <Form.Select
                        size = "sm"
                        value={dataSrc|| ''}
                        onChange={(e) => handleSelectdataSrc(e.target.value)}
                        className=""
                    >
                        <option value="">Select an option (Clear Selected)</option>
                        {DataSrcItem.map((item, index) => (
                            <option key={index} value={item}>
                                {item}
                            </option>
                        ))}
                    </Form.Select>
                </Form>


                <div className="dflex justify-content-between">
                    <div className="w-40">
                        <label className="mt-2 mb-1">Select start date</label>
                        <Form>
                            <Form.Control
                                type="date"
                                placeholder="Select a date"
                                className="form-control"
                                value={selectedDate1}
                                onChange={handleDateChange1}
                            />
                        </Form>
                    </div>
                    <div className="w-40">
                        <label className="mt-2 mb-1">Select end date</label>
                        <Form>
                            <Form.Control
                                type="date"
                                placeholder="Select a date"
                                className="form-control"
                                value={selectedDate2}
                                    onChange={handleDateChange2}
                            />
                        </Form>
                    </div>
                </div>
            </div>

            <div className="dflex justify-content-between">
                <Button className="mx-auto d-block mt-4 mb-4" variant="primary"
                        onClick={resetHighlightedId}>View Reset</Button>

                <Button className="mx-auto d-block mt-4 mb-4 ml-5" variant="primary"
                        onClick={addPanel}>Create a new panel</Button>
            </div>




        </div>

        <div className="dflex">
            {panels.map(panel => (
                <Dplot
                key={panel.id}
                onDelete={() => deletePanel(panel.id)}
                selectedDate1={selectedDate1}
                selectedDate2={selectedDate2}
                dataSrc={dataSrc}
                disabled={!accessToken}
                panelId={panel.id}
                highlightedId={highlightedId}
                updatehighlightedId={updatehighlightedId}
                />
            ))}
        </div>
    </div>

  );
}

const Panel = ({ onDelete }) => {
    return (
        <div>
            <p>Panel Content</p>
            <button onClick={onDelete}>Delete Panel</button>
        </div>
    );
};

export default App;
